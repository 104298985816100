@import-normalize;
@import 'styles/_vars';

@font-face {
  font-family: 'Orkney';
  src: url('assets/fonts/orkney/Orkney Regular.woff') format('woff'),
    url('assets/fonts/orkney/Orkney Regular.otf') format('opentype'),
    url('assets/fonts/orkney/Orkney Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-family: 'Orkney', Arial, sans-serif;
}

html,
body,
* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 90%;
}

body {
  background-color: $bg-primary;
  font-size: 1rem;
}

*:focus {
  outline: none;
}

button {
  appearance: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $font-primary;

  &:hover {
    color: $link-active;
  }
}
