@import 'styles/_vars';

.base {
  display: flex;
  justify-content: start;
  align-items: center;

  .info {
    font-size: 0.84rem;
    margin-left: $margin-1;
  }
}
