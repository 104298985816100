@import 'styles/_vars';

.base {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  overflow: hidden;
  padding: 0;
  margin: 0;

  > div {
    height: 145px;

    ul {
      height: 98px;
      overflow-y: scroll;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        height: 1.6rem;
      }
    }
  }

  .dropdownRowSelectAll {
    height: 28px;
    margin-top: 10px;
    margin-bottom: 8px;
    border-bottom: solid 1px lightgrey;
  }
}
