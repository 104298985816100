@import 'styles/_vars';

.base {
  position: relative;
  display: inline-block;
  border: solid 1px $outline;
  border-radius: $border-radius;
  flex: 0 0 30%;
  margin: 7px 9px;
  padding: 9px 19px 9px 3px;
  min-width: 250px;
  max-width: 500px;

  &.removedTerm {
    background-color: lightgrey;
  }

  .value {
    display: block;
    padding: 0 0 0 $padding-general;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
