@import 'styles/_vars';

.base {
  position: relative;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 1.1rem;

  .loadingContainer {
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    text-align: center;
  }

  @mixin messages {
    width: 97%;
    max-width: 1200px;
    margin: $margin-2 auto;
    line-height: 1.2rem;
  }

  .noResultsText {
    @include messages;
  }

  .error {
    @include messages;
    color: $font-error;
  }
}
