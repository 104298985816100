@import 'styles/_vars';

.options {
  font: inherit;
  font-size: 0.9rem;
  margin-right: 10px;
  padding: 4px 2px 2px 2px;
  border-radius: 3px;
  border-color: $outline;
}
