@import 'styles/_vars';
@import 'styles/_mixins';

$base-row-height: 4.5rem;

.base {
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  margin-bottom: 3px;

  ul {
    list-style-type: none;
    width: 20px;
    padding: 3px;
  }

  .table {
    position: relative;
    border-spacing: 0px;
    font-size: 1rem;

    .row {
      cursor: pointer;
      display: flex;
      min-height: $base-row-height;
      max-height: $base-row-height;

      &:hover {
        background-color: lighten($bg-blue, 3%);
      }

      &.header {
        position: sticky;
        top: 0;
        background-color: $bg-secondary;
        z-index: $zTableHeader;
        min-height: 2.2rem;
        font-weight: 600;
        text-align: center;
        font-size: 0.8rem;
      }

      &.extended {
        max-height: none;

        .col p {
          max-height: none;
        }
      }

      &.selected {
        background-color: lighten($bg-blue, 1%);
      }

      .col {
        padding: 9px 3px 9px 6px;
        flex-grow: 0;
        flex-shrink: 0;
        border-bottom: 1px solid lighten($border-light, 8%);

        p {
          max-height: 2.3rem;
          line-height: 1.2rem;
          overflow: hidden;
        }

        &.widthXSmall {
          flex-basis: 36px;
          text-align: center;
        }

        &.widthSmall {
          flex-basis: 65px;
        }

        &.widthMiddle {
          flex-basis: 105px;

          p {
            min-width: 105px;
          }
        }

        &.widthLong {
          flex-basis: 150px;

          p {
            min-width: 150px;
          }
        }

        &.widthVeryLong {
          flex-grow: 1;
          flex-basis: 200px;

          p {
            min-width: 200px;
          }
        }

        &.matchedTerms {
          flex-grow: 2;
          flex-basis: 200px;
          line-height: 1.6rem;
          padding: 3px;
          overflow: hidden;
        }

        &.rowExtended {
          margin-top: auto;
        }

        &.icon {
          padding: 0;
        }

        &.addPatent {
          font-size: 1.5rem;
          margin-top: 2px;
        }

        &.disabled {
          pointer-events: none;
          color: $font-tertiary;
        }
      }
    }

    .pubDate,
    .pubDateHeader {
      margin: 9px 0;
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.85rem;
    }

    .familyList {
      cursor: pointer;
      user-select: none;

      &::before {
        content: '\25B6';
        display: inline-block;
        margin-right: 6px;
        color: $bg-button;

        &:hover {
          color: darken($bg-button, 35%);
        }
      }
    }

    .familyListDown::before {
      transform: rotate(90deg);
    }

    .familyMember {
      background-color: $bg-quaternary;
    }

    @include dataTooltip;
  }

  .scrollBottomBoundary {
    text-align: center;
  }

  .inTableMessage {
    text-align: center;
    margin-bottom: 40px;
    opacity: 0.7;

    p {
      margin-top: $margin-1;
    }

    .outOfBoundaryLink {
      @include link;
    }
  }
}
