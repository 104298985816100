@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  display: flex;
  border-top: 1px solid $outline;
  padding-top: $padding-small;

  &:hover {
    background-color: $bg-blue;
  }

  @include dataTooltip;

  .data {
    width: 90%;

    p {
      height: 1.1rem;
      font-size: 0.7rem;
      text-indent: $padding-small;
      color: darken($font-tertiary, 10%);
      text-transform: capitalize;

      &:nth-child(1) {
        width: 95%;
        font-size: 0.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        color: darken($font-tertiary, 40%);
      }
    }
  }

  .actions {
    display: none;
    padding: 0;
  }

  &:hover {
    .actions {
      display: inline-block;
      cursor: pointer;
      vertical-align: top;
    }
  }
}
