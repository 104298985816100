@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  .outOfBoundaryLink {
    @include link;

    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .filterSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: $bg-secondary;
    margin-bottom: $margin-1;

    @include border;

    &.hidden {
      display: none;
    }

    .filter {
      width: 240px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 5px;
      padding-right: 5px;

      > div {
        margin-bottom: 10px;
      }

      &.termsSelector {
        flex-grow: 1;
        max-width: 570px;

        @media (max-width: 1345px) {
          max-width: none;
        }
      }

      span {
        display: inline-block;
        padding-bottom: 3px;
        padding-left: 5px;
      }
    }

    .filteredValue {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @include dataTooltip;
  }
}
