@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  flex-grow: 1;
  margin: 0 15px 0 0;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  flex: 1;
  overflow-y: scroll;
  height: 215px;

  .data {
    display: flex;

    .key,
    .value {
      box-sizing: border-box;

      overflow: hidden;
      list-style: none;
    }

    .key {
      width: 25%;
      min-width: 100px;
      padding: 5px 0px 5px $padding-medium;
    }

    .value {
      display: flex;
      flex-direction: column;
      width: 75%;
      padding: 5px 5px 7px 10px;
      min-width: 220px;

      span {
        padding-top: 5px;
      }
    }
  }

  .title {
    position: sticky;
    top: 0;
    margin-bottom: 9px;
    padding: 9px 15px 9px 36px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: $bg-secondary;
    cursor: pointer;
    z-index: $zBasic;

    &:hover {
      color: $link-hover;
    }

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 14px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.3);
    }

    &.active {
      &:before {
        border-width: 14px 6px 0 6px;
        border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
      }
    }
  }

  &.hidden {
    flex-grow: 1;
    height: 37px;
    overflow: hidden;
  }

  @include dataTooltip;
}
