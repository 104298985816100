@import 'styles/_vars';

.base {
  flex-grow: 1;
  margin: 0 3px 0 0;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  flex: 1;
  overflow-y: scroll;
  height: 215px;

  p {
    padding: 5px 5px 7px $padding-medium;
  }

  .title {
    position: sticky;
    top: 0;
    margin-bottom: 9px;
    padding: 9px 15px 9px 36px;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: $bg-secondary;
    cursor: pointer;

    &:hover {
      color: $link-hover;
    }

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 14px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.3);
    }

    &.active {
      &:before {
        border-width: 14px 6px 0 6px;
        border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
      }
    }
  }

  &.hidden {
    flex-grow: 1;
    height: 37px;
    overflow: hidden;
  }

  .loadingImagesContainer {
    width: 100%;
    margin-top: 40px;
    text-align: center;
  }

  .imagesContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .thumbnailContainer {
    flex-basis: 33.333333%;
    padding: 5px;
    height: 100px;
  }

  .thumbnailBorder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    margin: 5px;
  }

  .thumbnailImg {
    width: 100%;
    max-height: 95px;
    padding: 5px;
  }
}
