@import 'styles/_vars';

.base {
  margin: 3px 7px;
  fill: $font-tertiary;
  cursor: pointer;
  width: 30px;
  max-width: 100%;

  &:hover {
    fill: $font-primary;
  }

  &.active {
    fill: $link-active;
  }

  &.indicator {
    cursor: default;

    &:hover {
      fill: $font-tertiary;
    }
  }

  &.loader {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: auto;
    background: none;
    display: block;
    shape-rendering: auto;
  }

  &.minimal {
    margin: 0;
    padding: 0;

    fill: $bg-button;
  }

  &.maxWidthNone {
    max-width: none;
  }

  &.verySmall {
    margin: 0px 7px;
    width: 14px;
    vertical-align: top;
  }

  &.desktopSearch {
    margin: 11px 3px 0;
  }

  &.inline {
    margin: auto $margin-1;
    vertical-align: center;

    &:hover {
      fill: $font-tertiary;
    }
  }

  &.rightCorner {
    float: right;
  }

  &.withMargin {
    margin: 7px;
  }

  &.iconSmaller {
    width: 22px;
    height: 22px;
    vertical-align: top;
  }

  &.iconSmall {
    width: 35px;
    height: 35px;
  }

  &.large {
    width: 60px;
    height: 60px;
  }

  &.logoFullMedium {
    width: 420px;
  }

  &.hoverBGLight {
    &:hover {
      background-color: #f0f0ca;
    }
  }

  &.grey {
    $default-fill: darken($font-tertiary, 8%);
    $hover-fill: darken($font-tertiary, 35%);

    fill: $default-fill;
    color: $default-fill;

    &:hover {
      fill: $hover-fill;
      color: $hover-fill;
    }
  }

  &.red {
    fill: $color-delete;
  }

  &.blue {
    fill: $color-info;
  }

  &.green {
    $greenFill: lighten($message-success-color, 15%);

    fill: $greenFill;

    &:hover {
      fill: $greenFill;
    }
  }

  &.noMargin {
    margin: 0;
  }

  &.headerLogo {
    float: left;
    height: 60px;
    width: 170px;
    margin: 0;
  }

  &.headerLogoSmall {
    float: left;
    height: 40px;
    width: 80px;
    margin-top: 9px;
  }

  &.absoluteCentred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.buttonBusy {
    position: absolute;
    display: block;
    height: 80%;
    width: unset;
    max-width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    border-width: 0px;

    svg {
      margin: -6px 0 -6px 0;
      height: calc(100% + 12px);
      width: calc(100% + 12px);
    }
  }

  &.hide {
    display: none;
  }

  &.magnifyingGlass {
    fill: grey;
  }

  &.darkenGrey {
    fill: darken($font-tertiary, 35%);
  }
}
