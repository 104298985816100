@import 'styles/_vars';

.base {
  .title {
    position: relative;
    font-size: 1.2rem;
    color: $font-primary;
    text-transform: uppercase;
    text-align: left;
    line-height: 1.1rem;
    padding: 8px 4px 8px 35px;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 3px;
      top: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 16px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.3);
    }
  }

  &.isOpen {
    .title {
      &:before {
        border-width: 16px 8px 0 8px;
        border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
      }
    }
  }

  &.filter {
    margin: 10px 0 $margin-1 0;

    .title {
      position: relative;
      padding: 0 0 0 23px;
      margin: 6px 0;
      font-size: 1.1rem;
      text-transform: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
        color: $link-active;
      }

      &:before {
        content: '';
        position: absolute;
        left: 3px;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 14px;
        border-color: transparent transparent transparent rgba(0, 0, 0, 0.3);
      }
    }

    .dropdown {
      display: none;
      padding: $padding-general;
    }

    &.isOpen {
      .title {
        &:before {
          border-width: 14px 6px 0 6px;
          border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
        }
      }

      .dropdown {
        display: block;
        overflow: visible;
        width: 100%;
        background-color: $bg-tertiary;
        border: solid 1px $outline;
        border-radius: $border-radius;
        line-height: 1.5;

        &:hover {
          li {
            background-color: $bg-secondary;
          }
        }
      }
    }
  }
}
