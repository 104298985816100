@import 'styles/_mixins.scss';

.base {
  display: inline-block;
  margin-left: 5px;

  .spinner > svg {
    cursor: default;
  }

  .formContainer {
    margin: 0 20px 20px 20px;

    p {
      margin-bottom: 12px;
    }

    .itemsContainer {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;

      .item {
        height: 35px;
        width: 180px;
      }
    }
  }
  .line {
    border-bottom: 1px solid rgb(191, 191, 191);
    margin: 5px -29px 20px -29px;
  }

  @include dataTooltip;
}
