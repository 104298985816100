@import 'styles/_vars';

.base {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $zBackdrop;

  .messageContainer {
    position: fixed;
    display: inline-block;
    overflow-y: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $bg-secondary;
    border: solid 1px $outline;
    border-radius: $border-radius;
    padding: $padding-general;
    z-index: $zModal;
    width: 90%;
    max-height: 80%;
    max-width: 720px;
    min-width: 280px;

    .message {
      padding: 15px;
      margin-bottom: 30px;
    }
  }

  &.exportModal {
    .messageContainer {
      max-width: 800px;
    }

    .message {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 1.3em;
      margin-bottom: 0;
      padding: 20px;
    }
  }
}
