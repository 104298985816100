$bridal-heath: #fffaf4;
$white: rgba(255, 255, 255, 1);
$beau-blue: rgba(197, 213, 228, 1);
$light-blue: #e5f1ff;
$zircon: #f4f8ff;
$orange-crayola: rgba(250, 107, 51, 1);
$orange-crayola-light: rgba(250, 107, 51, 0.85);
$light-grey: #d1d8d9;
$rich-black-fogra-29: rgba(0, 21, 36, 1);
$red: red;
$green: green;
$light-grey-transparent: rgba(209, 216, 217, 0.7);
$delicate-red: #e46161;
$blue: #7cccea;
$light-yellow: #fff09e;
$light-blue2: #bee6ff;
