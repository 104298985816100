@import 'styles/_vars';

.base {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  background-color: $bg-button;
  color: $color-button;
  border: solid 1px $bg-button;
  border-radius: $border-radius-small;
  font-size: 1.05rem;
  font-weight: 400;
  padding: 4px 10px;
  min-width: 70px;
  min-height: 2.2rem;

  &:hover {
    color: $bg-button;
    background-color: $color-button;
  }

  &:disabled {
    color: $color-button;
    background-color: lightgrey;
    border-color: $color-button;
  }

  &.transparentContainer {
    background-color: transparent;
    border: none;
    border-color: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    appearance: none;

    &:hover {
      background-color: transparent;
    }
  }

  &.removeButton {
    @extend .transparentContainer;
    color: $font-primary;
    font-size: 1.7rem;
    font-weight: 200;

    &:hover {
      font-weight: 400;
    }
  }

  &.link {
    @extend .transparentContainer;
    color: $font-primary;

    &:hover {
      color: $link-active;
    }
  }

  &.searchTermAction,
  &.removeDateAction {
    @extend .transparentContainer;
    position: absolute;
    padding: 0;
    margin: 0;
    color: $font-primary;
    font-size: 1.2rem;
    font-weight: 300;
    opacity: 0.5;
    line-height: 1;
    height: auto;
    width: auto;

    &.searchTermAction {
      right: 7px;
      top: 2px;
    }

    &.removeDateAction {
      min-width: 20px;
      right: 5px;
      top: -3px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.resultPageTab {
    margin: 0;
    border-radius: 0;

    &-active {
      color: $bg-button;
      background-color: $color-button;
    }
  }

  &.long {
    min-width: 110px;
    max-width: 250px;
    width: 100%;
  }

  &.veryLong {
    width: 500px;
  }

  &.thin {
    font-size: 0.95rem;
    min-height: 1.9rem;
  }

  &.grey {
    background-color: $bg-transparent;
    border-color: $bg-transparent;

    &:hover {
      background-color: $bg-secondary;
    }
  }

  &.greyInverted {
    background-color: $bg-secondary;
    border-color: $bg-transparent;

    &:hover {
      background-color: $bg-transparent;
    }
  }

  &.thick {
    font-weight: 600;
  }

  &.colorNegative {
    color: $bg-button;
    background-color: $color-button;

    &:hover {
      background-color: $bg-button;
      color: $color-button;
    }
  }
}
