@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: $projects-adjusted-width;
  width: $projects-adjusted-width;

  &.projectsHidden {
    min-width: $projects-adjusted-width-max;
    width: $projects-adjusted-width-max;
  }

  //TODO: below 1195px width, projects panel can cover items on the page

  .filtersArrow {
    display: flex;
    align-self: center;
    transform: rotate(-90deg);

    &.open {
      transform: none;
    }
  }

  .metaTadaTables {
    display: inline-block;
    display: flex;
    font-size: 1rem;
    margin: 0px 0 10px 0;

    .searchId {
      font-size: 0.9rem;
      font-style: italic;
    }
  }

  .tableOptions {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    line-height: 1.5rem;
    margin-top: 5px;
    margin-bottom: 9px;

    .titleLine {
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 9px $margin-3 5px 5px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      position: relative;
    }

    .keepRight {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      .downloadText {
        align-self: center;
        margin: 0 $margin-2 5px $margin-1;
        line-height: 1.7rem;
        font-style: italic;
      }
    }
  }

  .selectedResult {
    top: 460px;
  }

  .results {
    box-sizing: border-box;
    top: 65px;
    height: 350px;
    margin-bottom: 45px;

    &.resultsListExtended {
      $tableHeight: 70vh;

      height: $tableHeight;

      &.shrink {
        height: calc(#{$tableHeight} - 200px);
      }
    }

    .paginationArea {
      display: flex;
      flex-direction: row;
      position: relative;
      float: right;
      bottom: 20px;
      right: 10px;

      .resultsCount {
        margin-left: 10px;
      }
    }
  }

  .extendResultsButton {
    text-align: center;
  }

  .resultDetails {
    display: flex;
    margin-bottom: 0;
    max-height: unset;
  }

  @include dataTooltip;
}

.error {
  margin: $margin-2 auto;
  color: $font-error;
}
