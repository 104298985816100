@import 'styles/_vars';
@import 'styles/_mixins';

$checkboxMarginRight: 5px;

.base {
  background-color: $bg-secondary;
  margin: 0 0 $margin-2 0;
  padding: $padding-general;
  border: solid 1px $outline;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  .filterSelection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $bg-secondary;
    margin-bottom: $margin-1;

    &.hidden {
      display: none;
    }

    .filter {
      width: 240px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 5px;
      padding-right: 5px;

      > div {
        padding-bottom: 10px;
        padding-top: 5px;
      }

      span {
        display: inline-block;
        padding-bottom: 3px;
        padding-left: 5px;
      }
    }

    .filteredValue {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @include dataTooltip;
  }
}
