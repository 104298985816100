@import 'styles/_vars';

.base {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 7px;
  margin-bottom: 9px;
  padding-left: 24px;

  span {
    display: inline-block;
    vertical-align: sub;
    font-size: 1.2rem;
    line-height: 1.2rem;

    &.disabled {
      color: $font-tertiary;
    }
  }

  &.fontSmall {
    span {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  &.fontSmallSub {
    span {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  &.grey {
    input {
      border-color: $light-grey;
      &:checked,
      &:active {
        background-color: $light-grey;
      }
    }
  }

  input {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 20px;
    width: 20px;
    appearance: none;
    border: 1px solid $border-active;
    border-radius: $border-radius-small;
    outline: none;
    background-color: $bg-secondary;
    cursor: pointer;

    &:checked,
    &:active {
      background-color: $border-active;
    }

    &:disabled {
      border-color: $outline;
      background-color: $bg-secondary;
    }

    &:checked {
      &::before {
        content: '\2713';
        display: block;
        color: $font-secondary;
        position: absolute;
        left: 3px;
        top: -1px;
        font-size: 1.2rem;
      }
    }
  }
}
