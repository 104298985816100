@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  display: flex;
  flex-direction: row;

  span {
    margin: auto 5px;
  }
}
