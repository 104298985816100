@import 'styles/_vars';

$searchPadding: 10px;

@mixin onHover {
  outline: 5px dotted $bg-quaternary;
  font-weight: 600;
}

.dragged {
  @include onHover();
}

.base {
  width: 900px;
  margin: 0 auto;

  @media (max-width: 1010px) {
    margin: 0 40px;
  }

  .errorContainer {
    width: 90%;
    max-width: 720px;
    margin: 9px auto;
    text-align: center;

    &Filename {
      margin-bottom: 9px;
    }

    &Message {
      color: $font-error;
    }
  }

  .searchClickable {
    padding: $searchPadding;
    cursor: pointer;

    &:hover {
      @include onHover();
    }
  }

  .uploadWrapper {
    position: relative;
    overflow: hidden;
    padding: $searchPadding;

    &:hover {
      @include onHover();
    }

    &.disable:input {
      pointer-events: none;
      cursor: none;
    }

    svg {
      height: 100px;
      padding-bottom: 5px;
      margin-bottom: 4px;
      cursor: initial;
    }
  }

  .uploadInput {
    position: absolute;
    cursor: pointer;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    overflow: hidden;

    pointer-events: all;
  }

  .searchTextSelector {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    margin: 0 auto 15px;
    background-color: $bg-secondary;
    border: solid 1px $outline;
    border-radius: $border-radius;
    padding: $padding-general;
  }

  .searchContainer {
    background-color: $bg-secondary;
    border: solid 1px $outline;
    border-radius: $border-radius;
    padding: $padding-general;

    &NextContainer {
      text-align: right;
    }
  }
}
