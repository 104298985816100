@import 'styles/_vars';

.base {
  padding: 15px;
  margin: 0 15px 0 0;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  padding: $padding-general;
  flex: 1;
  white-space: pre-wrap;
  font-size: 1.1rem;
  line-height: 1.4;

  .title {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 9px;

    &:hover {
      color: $link-hover;
    }
  }

  &.hidden {
    height: 37px;
    max-width: 140px;
    overflow: hidden;
    transform: rotate(270deg) translate(-100%, 126px);
    transform-origin: left;
    margin-left: -110px;
  }

  .selectedTermHighlight {
    background-color: $highlight-term-color;
  }
}
