@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  width: 220px;

  .addButtonWrapper,
  .arrowWrapper {
    display: none;
  }

  &.withButton {
    .addButtonWrapper {
      display: initial;
      position: relative;
      top: -9px;
      right: -25px;
    }
  }

  &.withArrow {
    .arrowWrapper {
      display: initial;
      position: relative;
      top: -18px;
      right: 10px;

      svg {
        transform: rotate(180deg);
      }

      &.clicked {
        svg {
          transform: none;
        }
      }
    }
  }
}
