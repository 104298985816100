@import 'styles/_vars';

$button-with: 60px;

.base {
  position: fixed;
  right: 10px;
  bottom: 7px;
  z-index: $zBasic;

  &.projectsOpened {
    right: 20px;
    width: calc(#{$panel-width} + #{$button-with});
    min-width: calc(#{$panel-width} + #{$button-with});
  }

  .scrollBtn {
    display: none;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: $button-with;
    height: $button-with;
    background-color: white;
    border: 1px solid darken($bg-primary, 25%);
    border-radius: 15px;
    font-size: 0.8rem;
    color: darken($font-tertiary, 35%);
    animation: fadeIn 0.5s;
    transition: opacity 0.5s;

    &.showScrollButton {
      display: flex;
    }

    &:hover {
      opacity: 1;
      background-color: rgb(242, 242, 242);
      color: $font-primary;
      border-color: $font-primary;
      svg {
        fill: $font-primary;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
