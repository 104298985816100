@import 'styles/_vars';

.base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zMaintananceMessage;

  .overlay {
    bottom: 0;
    height: 100vh;
    background-color: $bg-transparent;
    top: $header-height;
  }

  .header {
    height: $header-height;
    display: flex;
    align-items: center;
    background-color: darken($orange-crayola, 5%);

    .messageTitle {
      font-size: 1rem;
      font-weight: 600;
      margin: $margin-1;
    }

    .message {
      margin: $margin-1;
      font-size: 1em;
      line-height: 1.2;
    }
  }
}
