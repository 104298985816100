@import 'styles/_vars';

.base {
  display: inline-block;
  position: relative;

  :global .DayPicker-Day--selected {
    background-color: $bg-button !important;
  }

  :global .DayPicker-wrapper {
    padding-bottom: 5px;
  }

  :global .DayPicker-Footer {
    padding: 5px 15px;
  }

  :global .DayPickerInput-Overlay {
    z-index: $zPatentFilters;
  }

  :global .DayPickerInput > input {
    cursor: pointer;
    padding: 3px;
    line-height: 1.5;
    border: solid 1px $outline;
    border-radius: 3px;
    width: 100%;
  }
}

.shiftLeft :global .DayPickerInput-Overlay {
  left: -131px;
}
