@import 'styles/_vars';

.base {
  position: relative;
  display: block;
  text-align: left;
  margin-right: 15px;
  margin-top: 12px;

  .error {
    font-size: 0.8rem;
    color: $font-error;
    margin: 2px 12px;
  }

  .magnifyingGlassWrapper {
    position: absolute;
    padding-top: 9px;
    left: 9px;
    width: 25px;
  }

  .loaderWrapper {
    position: absolute;
    padding-top: 6px;
    left: 7px;
  }
}
@media (max-width: 1160px) {
  .base {
    margin-right: 5px;
  }
}
