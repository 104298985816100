@import 'styles/_vars';

.base {
  padding: 3px 3px 3px 6px;
  line-height: 1.5;
  border: 0;
  width: 100%;
  border-radius: 3px;

  &.marginBottom {
    margin-bottom: $padding-general;
  }

  &.withBorder {
    border: solid 1px $outline;
  }

  &.bottomBorderOnly {
    border-bottom: solid 1px $outline;
  }

  input[type='search']::-webkit-search-decoration:hover,
  input[type='search']::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }

  &.serachBox {
    background-color: rgb(240, 240, 239);
    width: 235px;
    padding: 1px 10px 1px 32px;
    line-height: 2.2;
    border-radius: 15px;
    font-size: 1rem;

    @media (max-width: 1160px) {
      &.serachBox {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &::-webkit-search-decoration:hover,
    &::-webkit-search-cancel-button:hover {
      cursor: pointer;
    }
  }
}
