@import 'styles/_vars';

.searchItem {
  position: relative;
  padding: 20px $padding-general;

  .loadingContainer {
    width: 100%;
    text-align: center;
    z-index: $zBasic;
  }

  .error {
    display: inline-block;
    color: $font-error;
  }

  textarea {
    width: 100%;
    margin-bottom: $margin-1;
    border: solid 1px $outline;
    min-height: 200px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: $padding-general;
  }

  .removeContainer {
    display: inline-block;
    position: absolute;
    top: -10px;
    right: -2px;
  }

  .buttonCheckContainer {
    .buttons {
      text-align: right;
      margin-top: 5px;
    }

    .check {
      text-align: right;
      margin-top: $margin-1;
    }
  }

  .searchNameContainer {
    display: flex;
    align-items: center;
    margin-bottom: $margin-1;

    label {
      display: inline-block;
      margin-right: $margin-1;
      font-size: 1rem;
    }

    input {
      border: solid 1px $outline;
      border-radius: 3px;
      padding: $padding-small;
      min-width: 400px;
      font-size: 1.1rem;
    }
  }

  .advancedOptions > div,
  .searchTextSection > div {
    margin-bottom: 5px;
  }
}
