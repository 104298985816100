@import 'styles/_vars';

.base {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 15px;

  min-width: $projects-adjusted-width;
  width: $projects-adjusted-width;

  &.projectsHidden {
    min-width: $projects-adjusted-width-max;
    width: $projects-adjusted-width-max;
  }

  @media (max-width: 1000px) {
    min-width: none;
    width: 95%;

    &.projectsHidden {
      min-width: none;
      width: 95%;
    }
  }

  .topActions {
    display: flex;
    margin-bottom: $margin-1;

    .leftTopPanel {
      width: 47%;

      .patentTitle {
        display: flex;
        font-weight: bold;
        margin-bottom: 8px;
        font-size: 1.1rem;

        p {
          width: 500px;
          margin-top: 6px;
        }
      }

      .newSearchSection {
        display: flex;
        align-items: flex-start;

        .searchOptions {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .divider {
      width: 3%;
      border-right: $bg-transparent solid 2px;
    }

    .matchTermsArea {
      display: flex;
      width: 47%;
      flex-direction: column;
      padding-left: $margin-1;

      p {
        margin: 6px 6px $margin-1 6px;
        font-size: 1.1rem;
      }

      .matchTerms {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $margin-1;
        max-height: 3.7rem;
        overflow: hidden;

        &.extended {
          max-height: none;
        }

        p {
          font-size: 0.9rem;
          color: darken($font-tertiary, 30%);
          font-weight: 300;
          font-style: italic;
        }

        .term {
          display: inline-block;
          border-radius: $border-radius-small;
          background-color: lighten($bg-blue, 3%);
          margin: 3px;
          line-height: 1.4rem;
          padding-right: 3px;
          padding-left: 3px;
          overflow: ellipsis;
        }
      }
    }

    .termsArrow {
      margin-top: 3rem;
      min-width: 40px;
      width: 3%;
    }
  }

  .metadataTables {
    display: flex;
    margin-bottom: $margin-1;
    max-height: 215px;
    justify-content: space-evenly;
  }
}

.error {
  margin: $margin-2 auto;
  color: $font-error;
}
