@import 'styles/_vars';

.base {
  position: relative;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  padding: 60px $padding-general $padding-general $padding-general;
  cursor: pointer;

  .name {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $bg-tertiary;
    padding: $padding-general;
    border-radius: $border-radius;
    font-size: 1.1rem;
  }

  .submimttedTextContainer {
    text-align: center;
  }
}
