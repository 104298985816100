@import './_vars.scss';

@mixin dataTooltip {
  [data-tooltip] {
    position: relative;
    overflow: visible;
  }

  [data-tooltip]::before {
    content: attr(data-tooltip);
    position: absolute;
    visibility: hidden;
    padding: 3px;
    font-size: 0.85rem;
    letter-spacing: 0.3px;
  }

  [data-tooltip]:hover::before {
    visibility: visible;
    opacity: 1;
    width: 138px;
    top: 5px;
    left: 45px;
    background-color: #bee6ff;
    z-index: $zTooltip;
    color: white;
    white-space: pre-wrap;
  }
}

@mixin border {
  border: solid 1px $outline;
  border-radius: $border-radius;
  padding: $padding-general;
}

@mixin link {
  text-decoration: none;
  cursor: pointer;
  color: $font-primary;

  &:hover {
    color: $link-active;
  }
}
