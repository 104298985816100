@import 'styles/_vars';

.base {
  position: relative;
  display: block;
  text-align: left;

  .hamburgerContainer {
    margin-right: 40px;
    margin-top: 13px;

    &.open {
      transform: rotate(90deg);
    }
  }

  .menuList {
    position: absolute;
    background-color: $bg-secondary;
    top: $header-height;
    right: -1px;
    margin-top: -1px;
    width: 140px;
    list-style: none;
    padding: 10px;
    border: solid 1px $outline;
    border-top: none;

    a {
      font-size: 15px;
      margin-bottom: 10px;
    }

    button {
      margin: 8px;
    }
  }
}
