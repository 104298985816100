@import 'styles/_vars';

.base {
  display: inline-block;
  cursor: pointer;
  margin-right: 12px;
  border-radius: $border-radius-small;
  background-color: lighten($bg-quaternary, 2%);
  margin: 3px;
  padding-right: 3px;
  padding-left: 3px;
  text-align: center;

  &:hover {
    font-weight: 600;
    background-color: darken($bg-quaternary, 2%);
  }

  &.active {
    color: $link-active;
    font-weight: 600;
  }
}
