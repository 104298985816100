@import 'styles/_vars';

.base {
  text-align: center;
  width: 100%;

  .messageTitle {
    font-size: 3em;
    margin: 0 auto;
  }

  .message {
    margin-top: $margin-3;
    font-size: 1.8em;
    line-height: 2;
  }

  .link {
    margin: $margin-4;
    font-size: 2em;
  }
}
