@import 'styles/_vars';

.base {
  display: flex;
  justify-content: center;
  align-items: strech;
  font-size: 0.9rem;
  margin: $margin-1 auto $margin-1;
  height: 110px;

  .reqsColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
  }
}
