@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  position: relative;
  display: flex;
  width: 95%;
  margin-bottom: 15px;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  padding: $padding-medium $padding-medium $padding-small $padding-medium;

  .search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 95%;

    .searchName {
      display: flex;
      flex-wrap: nowrap;
      flex-grow: 1;
      flex-basis: 200px;
      max-width: 600px;

      &.editable {
        cursor: pointer;
      }

      .searchInput {
        font-size: 1rem;
        border: solid 1px $outline;
        border-radius: 3px;
        padding: 2px 9px;
        width: 100%;
      }
    }

    .statusArea {
      width: 105px;
      font-size: 0.9rem;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 0;
      border-radius: $border-radius-small;

      &.inProgress {
        background-color: $success-status-color;
      }

      &.error {
        background-color: $error-status-color;
      }
    }

    .dateTimeArea {
      width: 115px;
      text-align: center;
      font-size: 1rem;
    }

    a {
      display: inline-block;
      flex-grow: 1;
      flex-basis: 160px;
      max-width: 270px;
      width: 100%;
      margin: auto 7px;
    }

    .infoArea {
      width: 18px;
    }

    .buttonContainer {
      width: 90px;
      text-align: right;

      .archiveContainer {
        display: inline-block;
        margin-right: 16px;
      }
    }

    @include dataTooltip;
  }

  @media (max-width: 980px) {
    .search {
      a {
        flex-basis: 80px;
        text-align: left;
      }

      .dateTimeArea {
        width: 50%;
        text-align: left;
      }

      .buttonContainer {
        width: 50%;
      }
    }
  }
}
