@import 'styles/_vars.scss';

.base {
  margin: 0 $margin-1 10px $margin-1;

  .filters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .category:not(:last-child) {
      margin-right: $margin-1;
    }

    span {
      display: block;
      align-self: center;
      padding-top: 3px;
      margin-right: 5px;
      font-size: 0.8rem;
    }

    .category {
      display: flex;
      flex-wrap: wrap;

      &.hidden {
        display: none;
      }

      .label {
        display: flex;
        align-items: center;
        height: 1.6rem;
        max-width: 10rem;
        font-size: 0.9rem;
        padding: 5px;
        margin-right: 4px;
        margin-bottom: 5px;

        background-color: $bg-primary;
        border: 1px solid $outline;
        border-radius: $border-radius-small;

        span:first-child {
          margin-right: 4px;
          width: 90%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .removeFilter {
          cursor: pointer;
          align-self: center;
          color: darken($font-tertiary, 15%);
        }
      }

      .label:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
