@import 'styles/_vars';

@mixin inputBox {
}

.base {
  background-color: $bg-secondary;
  padding: $padding-general;
  margin-bottom: $margin-2;
  border: solid 1px $outline;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  max-height: 400px;
  overflow: hidden;

  .terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $margin-1;
    overflow: auto;
    max-height: 280px;

    .addTerm {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: solid 1px $outline;
      border-radius: $border-radius;
      flex: 0 0 30%;
      margin: 7px 9px;
      padding: 9px 19px 9px 3px;
      min-width: 250px;
      max-width: 500px;

      input {
        border: none;
        padding: 0 0 0 9px;
      }

      .buttonWrapper {
        position: relative;
        top: -7px;
        right: -19px;
      }
    }
  }

  .loadingContainer {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .termsCount {
    font-size: 1.1rem;
    font-style: italic;
    float: right;
    margin-right: 18px;
  }

  .filterSortBar {
    display: flex;
    margin: 15px auto;
    width: 97%;

    input {
      padding: 3px 9px;
      width: 100%;
      line-height: 1.2;
      border: solid 1px $outline;
      margin-right: 4px;
    }
  }
}
