@import 'styles/_vars';

.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  .error {
    margin: 0 auto $margin-1 auto;
    color: $font-error;
  }

  .successMessage {
    font-size: $message-success-font-size;
    color: $message-success-color;
    margin: 15px 0px;
  }

  .loginContainer {
    margin: 30px auto;
    background-color: $bg-secondary;
    border: solid 1px $outline;
    border-radius: $border-radius;
    padding: $padding-medium;
    width: 95%;
    max-width: 720px;
    max-height: 95%;
    text-align: center;
    font-size: 1.4rem;

    form {
      margin-top: $margin-1;

      label {
        display: inline-block;
        width: 25%;
        margin-bottom: 38px;
        font-size: 1.2rem;
      }

      input {
        width: 50%;
        line-height: 1.4;
        font-size: 1.2rem;

        &::placeholder {
          font-size: 1rem;
          color: lightgrey;
        }
      }
    }

    .linkContainer {
      margin: 30px auto;

      a {
        display: block;
        color: $font-primary;
        &:hover {
          color: $link-active;
        }
        padding: $padding-small;
        font-size: $link-font-size;
      }
    }
  }
}
