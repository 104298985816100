@import 'styles/_vars';

.base {
  text-align: left;
  line-height: 1.5;
  font-size: 1.2rem;

  h2 {
    text-align: center;
  }

  .termsCheckbox {
    display: flex;
    justify-content: center;
    margin-bottom: $margin-1;
    white-space: pre-wrap;

    span {
      cursor: pointer;
      color: $font-primary;
      font-weight: 600;

      &:hover {
        color: $link-active;
      }
    }
  }
}
