@import 'styles/_vars';

.base {
  display: flex;
  padding-top: $header-height;
  min-height: 100vh;
  min-width: 100vw;
  padding: 80px $margin-1 40px $margin-1;

  a {
    color: $font-primary;
  }
}
