@import 'styles/_vars';

.base {
  padding: 15px;
  margin: 0 3px 0 0;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  padding: $padding-general;
  flex: 1;
  white-space: pre-line;

  .patentTextsContainer > div {
    margin-bottom: $margin-1;
  }

  .patentTextsContainer {
    position: relative;
    font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1.1rem;
    line-height: 1.4;
    word-break: break-word;

    .typedTextHighlight {
      background-color: $highlight-text-color;
    }

    .claimTextFilter {
      margin-bottom: $margin-1;

      input {
        padding: 3px;
        font-size: 1.1rem;
        line-height: 1.4;
        border: solid 1px $outline;
        width: 100%;
      }
    }

    p {
      margin-bottom: $margin-1;
    }

    .selectedTermHighlight {
      background-color: $highlight-term-color;
    }

    .claimsContainer {
      margin-top: $margin-2;
    }

    claim {
      position: relative;
      display: block;
      padding: 0 0 22px 15px;

      &:before {
        content: attr(num);
        position: absolute;
        left: -7px;
        top: -7px;
        font-weight: bold;
        border: solid 1px grey;
        padding: 1px 6px;
        border-radius: 21%;
      }

      &.hidden {
        display: none;
      }

      claim-text {
        display: block;

        claim-text {
          position: relative;
          padding-left: 25px;
          padding-bottom: 10px;

          &:before {
            position: absolute;
            content: ' - ';
            left: 2px;
            top: 1px;
          }
        }
      }
    }
  }
}
