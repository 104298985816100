@import 'styles/_vars';

.base {
  position: relative;
  z-index: $zPatentFilters;
  width: 240px;
  height: 63px;
  padding: 0;
  margin: 0;

  > div {
    ul {
      height: 135px;
      overflow-y: scroll;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        height: 1.6rem;
      }
    }
  }

  .arrowWrapper {
    display: initial;
    position: relative;
    top: -17px;
    right: 3px;

    &.clicked {
      svg {
        transform: none;
      }
    }
  }

  .dropdownRowSelectAll {
    height: 28px;
    margin-top: 10px;
    margin-bottom: 8px;
    border-bottom: solid 1px lightgrey;
  }
}
