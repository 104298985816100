@import 'styles/_vars';

.base {
  margin-bottom: 50px;

  .listActions {
    display: flex;
    margin: $margin-1 0px 5px 0px;

    select {
      margin-left: $margin-1;
    }
  }
}
