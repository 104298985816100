@import 'styles/_vars';

.base {
  position: fixed;
  display: block;
  z-index: $zAppHeader;
  top: 0;
  left: 0;
  height: $header-height;
  width: 100%;
  overflow: visible;
  font-size: 1.1rem;
  background-color: $bg-secondary;
  border-bottom: 1px solid $outline;

  nav {
    position: relative;
    float: left;
    height: 100%;

    .link {
      display: inline-block;
      vertical-align: middle;
      width: 170px;
      text-align: center;
      height: 100%;

      &Text {
        margin-top: 20px;
      }

      &:hover {
        background-color: $bg-tertiary;
      }

      &.activeNavLink {
        background-color: $bg-tertiary;
      }
    }
  }

  .mainMenuContainer {
    float: right;
    display: flex;

    .username {
      font-size: 1rem;
      padding: 22px 20px;
      font-style: italic;
      color: darken($font-tertiary, 30%);
      max-width: 250px;
    }
  }

  @media (max-width: 1240px) {
    .mainMenuContainer {
      .username {
        max-width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media (max-width: 1000px) {
    nav {
      .link {
        width: 140px;
      }
    }

    .mainMenuContainer {
      .username {
        max-width: 80px;
        padding: 22px 5px;
      }
    }
  }

  @media (max-width: 830px) {
    .mainMenuContainer {
      .username {
        display: none;
      }
    }
  }

  @media (max-width: 745px) {
    .mainMenuContainer {
      flex-direction: column;
    }
  }
}
