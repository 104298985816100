@import 'styles/_vars';

$list-spacing: 6px;
$spacing-summed-up: calc(
  4px + #{$list-spacing} * 10
); //width of the panel - border - margins - paddings
$input-width: calc(#{$panel-width} - #{$spacing-summed-up});
$font-color: darken($font-tertiary, 40%);

@mixin border {
  border: 1px solid $outline;
  border-radius: $border-radius-small;
}

.base {
  position: fixed;
  right: 15px;
  min-width: $panel-width;
  width: $panel-width;
  height: calc(100vh - 90px);
  padding: $padding-general $padding-small;
  @include border;
  background-color: $bg-secondary;
  color: $font-color;
  z-index: $zProjectsPanel;
  overflow-y: auto;

  &.panelHidden {
    min-width: $panel-hidden-width;
    width: $panel-hidden-width;
    height: 130px;
    overflow: hidden;

    .topActions {
      flex-direction: column;

      p {
        transform: rotate(270deg);
        margin-top: 45px;
        margin-left: -17px;
      }

      > svg {
        display: none;
      }
    }

    .projectsList {
      display: none;
    }
  }

  .arrow {
    height: 1.3rem;
    width: 3rem;
    transform: rotate(180deg);
    margin-top: 6px;
    margin-left: 10px;

    &.arrowRight {
      transform: rotate(90deg);
      margin-left: -9px;
      margin-top: 0;
    }

    &.patentsListArrow {
      position: absolute;
      margin-top: 3px;
      margin-left: -5px;

      &.arrowRight {
        margin-top: 8px;
        margin-left: -10px;
      }
    }
  }

  .topActions {
    * {
      display: inline-block;
    }

    p {
      position: relative;
      bottom: 3px;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0 auto 10px auto;
      color: $font-primary;

      &:hover {
        color: $link-hover;
      }
    }

    svg {
      float: right;
    }
  }

  input {
    @include border;
    margin: $padding-small;
    padding: $padding-small;
    color: $font-color;
    font-size: 1.1rem;
    width: $input-width;
  }

  .projectsList {
    .project {
      @include border;
      margin: $list-spacing;

      .projectName {
        padding: 11px 0px 0px 30px;
        color: $font-primary;
        font-size: 1.1rem;
        cursor: pointer;

        &.borderBottom {
          border-bottom: 1px solid $outline;
        }

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        input {
          @include border;
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
          width: $input-width;
          border: none;
          color: $font-color;
          font-size: 1.15rem;
        }
      }
    }

    .addProjectButton {
      cursor: pointer;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  & > ul {
    padding: 0;
  }

  li {
    padding: 0;
    cursor: pointer;
  }
}
