@import 'styles/_vars';

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  font-size: 1.4rem;
  margin: 0 auto;

  h4 {
    margin-bottom: $margin-2;
  }

  .verticalLine {
    display: inline-block;
    border-left: 1px solid #ccc;
    margin: 0 10px;
    height: 200px;
  }

  .successMessage {
    font-size: $message-success-font-size;
    color: $message-success-color;
    margin: 15px 0px;
  }

  .error {
    margin: 0 auto 10px auto;
    color: $font-error;
  }

  form {
    width: 95%;
    max-width: 520px;
    padding: 18px;
    text-align: center;
    font-size: 1.4rem;

    label {
      display: inline-block;
      width: 35%;
      margin-bottom: 38px;
      padding-right: 5px;
    }

    input {
      width: 65%;
      margin-bottom: $margin-1;
      line-height: 1.4;
      font-size: 1.2rem;

      &::placeholder {
        color: lightgrey;
      }
    }
  }

  Button {
    margin: $margin-1 0px;
  }
}
