@import './colours';

// Colours
$bg-primary: $bridal-heath;
$bg-secondary: $white;
$bg-tertiary: $zircon;
$bg-quaternary: lighten($orange-crayola, 35%);
$bg-transparent: $light-grey-transparent;
$bg-blue: $light-blue;
$bg-orange-light: $orange-crayola-light;

$bg-button: $orange-crayola;
$color-button: $white;

$color-delete: $delicate-red;
$color-info: $blue;

$success-status-color: lighten($green, 65%);
$error-status-color: lighten($orange-crayola-light, 35%);

$link-active: $orange-crayola;
$link-hover: $orange-crayola-light;
$link-font-size: 1.2rem;

$border-active: $orange-crayola;

$outline: $light-grey;

$font-primary: $rich-black-fogra-29;
$font-secondary: $white;
$font-tertiary: $light-grey;

$font-error: $red;

$highlight-term-color: $light-yellow;
$highlight-text-color: $light-blue2;

$header-height: 60px;

$border-radius-small: 6px;
$border-radius: 8px;
$border-light: $light-grey;

$padding-small: 6px;
$padding-general: 9px;
$padding-medium: 18px;

$margin-1: 15px;
$margin-2: 30px;
$margin-3: 45px;
$margin-4: 60px;

$message-success-font-size: 14px;
$message-success-color: $green;

$svg-icon-width: 22px;
$svg-icon-hover: $rich-black-fogra-29;

$panel-width: 350px;
$panel-hidden-width: 36px;

$projects-adjusted-width: calc(100vw - #{$panel-width} - 40px);
$projects-adjusted-width-max: calc(100vw - #{$panel-hidden-width} - 40px);

$zBasic: 1;
$zTableHeader: 10;
$zSelectedResult: 20;
$zProjectsPanel: 30;
$zPatentFilters: 40;
$zModal: 50;
$zTooltip: 60;
$zAppHeader: 70;
$zBackdrop: 80;
$zMaintananceMessage: 90;
