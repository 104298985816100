@import 'styles/_vars';

$font-color: darken($font-tertiary, 40%);

.base {
  position: absolute;
  max-height: 500px;
  width: 250px;
  background-color: $bg-secondary;
  font-size: 1rem;
  text-align: left;
  color: $font-color;
  border: 1px solid $outline;
  border-radius: $border-radius;
  z-index: $zBasic;

  input {
    color: $font-color;
  }

  .name {
    height: 1.7rem;
    margin: 6px;
    padding-top: $padding-small;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
