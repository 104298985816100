@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  margin: auto 5px;

  @include link;

  &.disabled {
    color: $font-tertiary;
    pointer-events: none;

    &:hover {
      color: $font-tertiary;
    }
  }
}
