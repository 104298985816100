@import 'styles/_vars.scss';

.base {
  width: 240px;

  > div {
    height: 145px;

    > div:first-child {
      padding-bottom: 10px;
    }
  }

  .note {
    padding: 22px 2px 0px 2px;
    color: darken($font-tertiary, 12%);
    font-size: 0.8rem;
  }
}
