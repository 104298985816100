@import 'styles/_vars';
@import 'styles/_mixins';

.base {
  position: sticky;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  background-color: $bg-secondary;
  border: solid 1px $outline;
  border-radius: $border-radius;
  margin-bottom: 9px;
  padding: $padding-general;
  z-index: $zSelectedResult;

  .row {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    line-height: 1.2rem;
    height: 2rem;
    overflow: hidden;
    padding: 5px $padding-general 5px 10px;

    &.rowWrapped {
      flex-wrap: wrap;
      line-height: 1.8rem;
      height: 4rem;
    }

    > div {
      display: flex;
      overflow: visible;

      &.dontShrink {
        flex-shrink: 0;
      }
    }

    .key {
      font-weight: 600;
      margin-right: 3px;
      padding-right: $padding-small;
      white-space: nowrap;

      &:after {
        content: ':';
      }
    }
    .title {
      white-space: nowrap;
    }

    .value {
      padding-right: $padding-general;
      overflow: hidden;

      &.arrow {
        position: relative;
        top: 8px;
        white-space: nowrap;
      }

      &:not(:last-child):after {
        content: '|';
        padding-left: $padding-general;
      }

      .icon {
        padding: 0;
        display: inline;
      }
    }

    &.terms {
      height: 2.2rem;

      .value {
        overflow: visible;

        &:nth-last-child(2):after {
          content: none;
        }
      }
    }
  }

  &.termsExtended {
    justify-content: flex-start;

    .row.terms {
      overflow: visible;
      height: initial;

      .value {
        white-space: pre-wrap;
      }
    }
  }

  @include dataTooltip;
}
